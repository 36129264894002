import { useParams } from "react-router-dom";

import { BasketEntry, BasketRfqEntry } from "@web/common/models/basket";
import { Loading } from "@web/ui";

import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { useGatherBasket } from "../../hooks/useGatherBasket";
import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";
import {
  GatherOrderCreation,
  QuotationApproval,
  RequisitionApproval,
  RfqApproval,
} from "./components";

export const OrderSummaryPage = () => {
  const { catalogItems, rfqItems, totalPrice } = useGatherBasket();
  const { orderRequisitionId } = useParams() as { orderRequisitionId: string };
  const lineItems: BasketEntry[] = Object.values(catalogItems);
  const orderCreationItems = LocalOrderReqService.convertBasketEntriesToCreationItems(catalogItems);
  const supplierId = LocalOrderReqService.extractSupplierIdFromBasketEntries(catalogItems);
  const rfqEntries: BasketRfqEntry[] = Object.values(rfqItems);
  const orderQuery = useOrderRequisitionQuery(
    {
      orderId: orderRequisitionId,
    },
    { enabled: Boolean(orderRequisitionId) }
  );

  if (!orderRequisitionId) {
    return (
      <GatherOrderCreation
        orderCreationItems={orderCreationItems}
        supplierId={supplierId}
        totalPrice={totalPrice}
      />
    );
  }

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (!orderQuery.isSuccess) {
    return null;
  }

  if (!orderQuery.data.port || !orderQuery.data.vessel) {
    throw Error("No port or vessel");
  }

  const orderRequisition = orderQuery.data;

  if (orderRequisition.type === "QUOTATION") {
    return (
      <QuotationApproval
        lineItems={lineItems}
        quotationId={orderRequisitionId}
        orderRequisition={orderRequisition}
        totalPrice={totalPrice}
      />
    );
  }

  if (orderRequisition.isRfqRequisition) {
    return (
      <RfqApproval
        requisitionId={orderRequisitionId}
        rfqItems={rfqEntries}
        orderRequisition={orderRequisition}
      />
    );
  }

  return (
    <>
      <RequisitionApproval
        lineItems={lineItems}
        requisitionId={orderRequisitionId}
        orderRequisition={orderRequisition}
        totalPrice={totalPrice}
      />
    </>
  );
};
