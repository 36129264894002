import { createReducer } from "@reduxjs/toolkit";
import _isNull from "lodash/isNull";

import { isDefined } from "@web/utils";

import { GATHER_KEY } from "../../config/consts";
import {
  clearGatherBasket,
  clearGatherBasketEntries,
  clearGatherBasketEntry,
  setGatherBasketEntry,
  setGatherBasketForRequisition,
  setGatherBasketForRfq,
  setGatherBasketQuantity,
  setGatherBasketRfqEntry,
  setGatherDraftId,
  setGatherPort,
  setGatherVessel,
  setPreconfigureOrderSetup,
} from "../actions";
import { GatherState } from "../models";

const { port, vessel, basket } = JSON.parse(localStorage.getItem(GATHER_KEY) || "{}");

export const defaultGatherState: GatherState = {
  port,
  vessel,
  basket: {
    catalogItems: basket?.catalogItems || {},
    requisitionItems: basket?.requisitionItems || {},
    rfqItems: basket?.rfqItems || {},
    isRfqRequisition: basket?.isRfqRequisition || false,
  },
};

export const gatherReducer = createReducer(defaultGatherState, (builder) => {
  builder
    .addCase(setGatherPort, (state, action) => {
      state.port = action.payload;
    })
    .addCase(setPreconfigureOrderSetup, (state, action) => {
      state.port = action.payload.port;
      state.deliveryDate = action.payload.deliveryDate;
      const dutyFreeDeclaration = action.payload.dutyFreeDeclaration;
      state.dutyFreeDeclaration =
        isDefined(dutyFreeDeclaration?.dutyFree) && !_isNull(dutyFreeDeclaration?.dutyFree)
          ? dutyFreeDeclaration
          : undefined;
    })
    .addCase(setGatherDraftId, (state, action) => {
      state.draftId = action.payload;
    })
    .addCase(setGatherVessel, (state, action) => {
      state.vessel = action.payload;
    })
    .addCase(setGatherBasketEntry, (state, action) => {
      state.basket.catalogItems[action.payload.sku.id] = action.payload;
    })
    .addCase(setGatherBasketQuantity, (state, action) => {
      state.basket.catalogItems[action.payload.id].quantity = action.payload.quantity;
    })
    .addCase(setGatherBasketRfqEntry, (state, action) => {
      const { quantity, item } = action.payload;
      state.basket.rfqItems[item.id].quantity = quantity;
    })
    .addCase(clearGatherBasketEntry, (state, action) => {
      if (state.basket.catalogItems[action.payload].initialQuantity) {
        state.basket.catalogItems[action.payload].quantity = 0;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.basket.catalogItems[action.payload];
      }
    })
    .addCase(setGatherBasketForRequisition, (state, action) => {
      const basketEntry = action.payload.items.reduce(
        (acc, e) => ({
          ...acc,
          ...(e.skuDetails
            ? {
                [e.skuDetails.id]: {
                  sku: e.skuDetails,
                  quantity: e.quantity,
                  initialQuantity: e.quantity,
                },
              }
            : {}),
        }),
        {}
      );
      state.port = action.payload.port;
      state.vessel = action.payload.vessel;
      state.basket.catalogItems = basketEntry;
      state.basket.requisitionItems = basketEntry;
      state.basket.requisitionId = action.payload.requisitionId;
      state.basket.isQuotation = action.payload.isQuotation;
    })
    .addCase(setGatherBasketForRfq, (state, action) => {
      state.basket.rfqItems = action.payload.items.reduce((acc, item) => {
        return {
          ...acc,
          [item.id]: { item, initialQuantity: item.quantity, quantity: item.quantity },
        };
      }, {});
      state.port = action.payload.port;
      state.vessel = action.payload.vessel;
      state.basket.requisitionId = action.payload.requisitionId;
      state.basket.isRfqRequisition = true;
    })
    .addCase(clearGatherBasket, (state) => {
      state.basket = {
        catalogItems: {},
        rfqItems: {},
        requisitionItems: {},
        isRfqRequisition: false,
        isQuotation: undefined,
      };
      state.vessel = undefined;
      state.port = undefined;
      state.dutyFreeDeclaration = undefined;
      state.deliveryDate = undefined;
      state.draftId = undefined;
    })
    .addCase(clearGatherBasketEntries, (state) => {
      state.basket = {
        catalogItems: {},
        rfqItems: {},
        requisitionItems: {},
        isRfqRequisition: false,
        isQuotation: undefined,
      };
    });
});
