import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { EmptyBasketUI, LineItemUI } from "@web/common/views/Basket/components";
import { containerPadding } from "@web/ui";

import { RootState } from "src/store";

import { BasketUI } from "../../components/Basket";
import routes from "../../config/routes";
import { useSetQuantity } from "../../hooks/useSetQuantity";
import { BasketHeader } from "./components/BasketHeader";

export const GatherBasketPage: React.FC = () => {
  const { vessel, port, basket } = useSelector((state: RootState) => state.gather);
  const lineItems = useMemo(() => Object.values(basket.catalogItems), [basket.catalogItems]);

  const { setQuantity } = useSetQuantity();

  if (vessel === undefined || port === undefined) {
    return <Navigate to={routes.orders} />;
  }

  return (
    <div className="flex flex-col h-full">
      <BasketHeader />
      <div className="bg-neutral_100 h-full ">
        <div className={`py-5 ${containerPadding} flex justify-center`}>
          {lineItems.length > 0 ? (
            <BasketUI isRequisition={false} isRfqRequisition={false}>
              {lineItems.map((lineItem) => {
                return (
                  <LineItemUI
                    initialQuantity={lineItem.quantity}
                    lineItem={lineItem}
                    key={lineItem.sku.id}
                    setQuantity={setQuantity}
                    productRoute={routes.gatherProduct}
                    isRestoreEnabled={false}
                  />
                );
              })}
            </BasketUI>
          ) : (
            <EmptyBasketUI homeRoute={routes.gatherCatalog} />
          )}
        </div>
      </div>
    </div>
  );
};
