import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { Money } from "@web/models";
import { Loading } from "@web/ui";

import { useCreateOrderMutation } from "src/hooks/useCreateOrderMutation";
import { useSystemMessage } from "src/hooks/useSystemMessage";
import { useVesselConfigurationQuery } from "src/hooks/useVesselConfiguration";
import { AppOrderCreationItem } from "src/typegens";

import routes from "../../../config/routes";
import { RootState } from "../../../store";

interface Props {
  orderCreationItems: AppOrderCreationItem[];
  totalPrice: Money;
  supplierId?: string;
}

export const GatherOrderCreation = ({ orderCreationItems, supplierId, totalPrice }: Props) => {
  const port = useSelector((state: RootState) => state.gather.port);
  const dutyFreeDeclaration = useSelector((state: RootState) => state.gather.dutyFreeDeclaration);
  const draftId = useSelector((state: RootState) => state.gather.draftId);
  const { setSystemMessage } = useSystemMessage();
  const deliveryDate = useSelector((state: RootState) => state.gather.deliveryDate);
  const vessel = useSelector((state: RootState) => state.gather.vessel);
  const vesselConfigurationQuery = useVesselConfigurationQuery(vessel?.id);
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOrderMutation();

  useEffect(() => {
    if (!orderCreationItems.length) {
      setSystemMessage({
        type: "failure",
        message: "No items were selected",
      });
      navigate(routes.orders);
    }
  }, [orderCreationItems.length, navigate, setSystemMessage]);

  if (vessel === undefined || port === undefined) {
    return <Navigate to={routes.orders} />;
  }

  if (vesselConfigurationQuery.isPending || vesselConfigurationQuery.isFetching) {
    return <Loading />;
  }

  if (!vesselConfigurationQuery.data) {
    return null;
  }

  const isCustomerOrderIdRequired = !vesselConfigurationQuery.data.allow.customerOrderIdOptional;

  const submitOrderHandler = async (orderSummary: OrderInfoFormValues) => {
    // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
    // In reality this will never happen thanks to the validation set up in OrderInfo component.
    if (!orderSummary.agentInformation) {
      throw Error("Agent info not defined");
    }
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date not defined");
    }
    if (!orderSummary.requesterInformation) {
      throw Error("Requester information not defined");
    }

    if (!supplierId) {
      return setSystemMessage({
        type: "failure",
        message: "Unknown supplier",
      });
    }

    const singleSupplierOrderComment = orderSummary.orderNotes;

    mutate({
      draftId,
      agentInformation: orderSummary.agentInformation,
      consolidated: orderSummary.consolidated || false,
      customerOrderId: orderSummary.customerOrderId,
      deliveryDate: orderSummary.deliveryDate ?? "",
      items: orderCreationItems,
      orderNotes: singleSupplierOrderComment,
      storageLabel: orderSummary.storageLabel,
      portId: port.id,
      requesterInformation: orderSummary.requesterInformation,
      supplierId: supplierId,
      vesselId: vessel.id,
      invoiceAccountId: orderSummary.invoiceAccountId,
      warehouseId: orderSummary.warehouseId,
      ...(orderSummary.dutyFreeDeclaration
        ? {
            dutyFreeDeclaration: {
              dutyFree: orderSummary.dutyFreeDeclaration.dutyFree,
              name: orderSummary.dutyFreeDeclaration.name,
              position: orderSummary.dutyFreeDeclaration.position,
            },
          }
        : {}),
    });
  };

  return (
    <FormLayout>
      <OrderInfo
        creationMode={"PURCHASER_ORDER_CREATION"}
        grandTotal={totalPrice}
        invoiceAccounts={vessel.invoiceAccounts ?? []}
        warehouses={vesselConfigurationQuery.data.warehouses}
        deliveryDate={deliveryDate}
        loading={isPending}
        nrLineItems={orderCreationItems.length}
        nrRfqItems={0}
        nrExtraItems={0}
        port={port}
        submitForm={submitOrderHandler}
        vessel={vessel}
        dutyFreeDeclaration={dutyFreeDeclaration}
        isCustomerOrderIdRequired={isCustomerOrderIdRequired}
        withOrderComment
        supplier={undefined}
        defaultAgentInformation={port.agentInformation}
      />
    </FormLayout>
  );
};
